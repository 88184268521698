<template>
  <div class="top-menu-filter-immo">
    <div class="right-block">
      <!-- EDIT VIEW  -->
      <edit-view
        :list="computedListView"
        :handleDropdownItemClick="handleDropdownItemClick"
        :updateOrderFields="updateOrderView"
      ></edit-view>
      <div class="vertical-line"></div>
      <!-- BLOCK FONCTION APPLY WHEN SELECTED SELECTEC  -->
      <template v-if="selectedItems.length > 0">
        <!-- UPDATE MULTI SUB STAGE  -->
        <div class="header-for-filter-immo" @click="showModalStage = true">
          <font-awesome-icon :icon="['far', 'pen-to-square']" />
          <div class="item-title">
            {{ $t('update_stade') }}
          </div>
        </div>
        <div class="vertical-line"></div>
        <!-- UPDATE MULT PROJECTS TAGS  -->
        <tags :handelUpdateTags="handelUpdateTags"></tags>
        <div class="vertical-line"></div>

        <!-- UPDATE MULT PROJECT OWNER  -->
        <asign
          :value="null"
          :updateFunction="assignProjects"
          :options="allUsers"
          labelKey="assign_owner"
        >
        </asign>
        <div class="vertical-line"></div>
      </template>

      <!-- EXPORT EXCEL  -->
      <div class="header-for-filter-immo" @click="exportProjects">
        <v-progress-circular
          indeterminate
          color="purple"
          :width="3"
          :size="18"
          v-if="getLoaderExportExcelProjectsImmo"
        ></v-progress-circular>
        <font-awesome-icon :icon="['far', 'up-from-bracket']" v-else />
        <div class="item-title">
          {{ $t('export_key') }}
        </div>
      </div>
    </div>

    <div class="left-block">
      <!-- FILTER REMOVE  -->
      <div v-if="contactFilter && contactFilter.length" class="mr-2">
        <v-btn
          icon
          :title="$t('delete_filter')"
          color="#3a167f"
          @click="removeFilter"
        >
          <v-icon>mdi-filter-remove-outline</v-icon>
        </v-btn>
      </div>

      <!-- Assign  -->
      <filter-check-mul
        :value="assingFilter"
        @handelFilter="handelFilterAssign"
        :options="allUsers"
        styleComponent="mr-2"
      ></filter-check-mul>
      <!-- TAG -->
      <!-- <filter-check-mul
        :value="TagFilter"
        @handelFilter="handelFilterTag"
        :options="getOptionsFieldTag"
        keyFilter="tag"
        label="label"
      ></filter-check-mul> -->
    </div>
    <portal to="portal-modal-immobilier">
      <modalStage
        v-if="showModalStage"
        :options="computedOptionsStep"
        @selectedItem="selectedStage"
        @close="closeModal('stage')"
      />
    </portal>
    <portal to="portal-child-modal-immobilier">
      <updateStage
        v-if="updateProjectState"
        @close="closeModal('sub_stage')"
        @ResetData="reloadDataForCsubStageUpdate"
        :stateToUpdate="stateToUpdate"
        :validateFunction="handelUpdateStage"
      />
    </portal>
    <AlertAction
      :message="alertMessage"
      :type="alertType"
      v-if="showAlert"
    ></AlertAction>
  </div>
</template>

<script>
import AlertAction from '@/components/CrmIla26Component/AlertAction.vue'
import { mapActions, mapGetters } from 'vuex'
import FilterCheckMul from '../immoTable/component/filterCheckMul.vue'
import EditView from './EditView.vue'
import asign from '../immoTable/component/asign.vue'
import updateStage from '../immoTable/component/updateStage.vue'
import tags from '../updateProjects/tags.vue'

export default {
  props: {
    showIcons: { type: Boolean },
    computedListView: { required: true },
    assing: { required: true },
    selectedItems: { required: true },
    step: { required: false, default: null },
    contact: { required: true },
    tag: { required: true }
  },
  data() {
    return {
      showIconCheck: false,
      iconFilterAssign: false,
      assingFilter: this.assing,
      showModalStage: false,
      stateToUpdate: null,
      updateProjectState: false,
      alertMessage: null,
      alertType: 'success',
      showAlert: false,
      contactFilter: this.contact,
      TagFilter: this.tag
    }
  },
  components: {
    EditView,
    FilterCheckMul,
    asign,
    modalStage: () => import('../updateProjects/stage/modalStage.vue'),
    updateStage,
    tags,
    AlertAction
  },

  computed: {
    ...mapGetters([
      'getCurentProjectType',
      'getProjectsTotalRowsTableBoard',
      'getTableBoard',
      'getHeaderTable',
      'getProjectsTotalRowsTableBoard',
      'getProjectsProcessing',
      'getProjectsTypes',
      'getProjectsExtraProject',
      'getListEditView',
      'allUsers',
      'getLoaderExportExcelProjectsImmo',
      'getOptionsFieldTag'
    ]),
    computedOptionsStep() {
      return this.step && this.step.stages ? this.step.stages : []
    }
  },

  methods: {
    ...mapActions([
      'UpdateViewTable',
      'updateOrderFields',
      'fetchBodyCustomContacts',
      'updateProjectsPropertys',
      'fetchEtapeSubstage',
      'fetchCustomFieldsByTypeProjet'
    ]),
    //update column order
    async updateOrderView(list, item) {
      this.updateOrderFields(list)

      const payload = {
        type_id: this.getCurentProjectType.id,
        field_id: list[item.addedIndex].id,
        order: item.addedIndex
      }

      await this.UpdateViewTable({ data: payload })
      this.$emit('refresh')
    },
    //export excel
    exportProjects() {
      this.$emit('exportProjects')
    },
    //assign Moethod
    //assign filter
    async handelFilterAssign(assign) {
      this.assingFilter = assign
      this.$emit('assingFilter', this.assingFilter)
    },
    async handelFilterContact(contact) {
      this.contactFilter = contact
      this.$emit('contactFilter', this.contactFilter)
    },
    async handelFilterTag(tag) {
      this.tagFilter = tag
      this.$emit('tagFilter', this.tagFilter)
    },
    removeFilter() {
      this.$emit('removeFilter')
    },
    async handleDropdownItemClick(item) {
      const payload = {
        type_id: this.getCurentProjectType.id,
        field_id: item.id,
        order: item.order,
        visibility: item.visibility
      }
      await this.UpdateViewTable({ data: payload })
      this.$emit('refresh')
    },

    // Assign
    async assignProjects(item) {
      const res = await this.updateProjectsPropertys({
        project_ids: this.selectedItems.map(i => i.id),
        project_owner: item.id
      })
      return res
    },
    //update stage
    async selectedStage(item) {
      this.stateToUpdate = item
      await this.fetchEtapeSubstage({
        cstage_id: item.id,
        stage: item
      })
      this.updateProjectState = true
    },
    handelUpdateStage(event) {
      this.updateProjectsPropertys({
        project_ids: this.selectedItems.map(i => i.id),
        csubstage_id: event.id,
        cstage_id: this.stateToUpdate.id
      })
      this.$emit('handelFilterTable')
      return 'data_updated'
    },
    async handelUpdateTags(tags) {
      const res = await this.updateProjectsPropertys({
        project_ids: this.selectedItems.map(i => i.id),
        tags: tags.map(i => i.text)
      })
      this.$emit('handelFilterTable')
      this.alertType = 'success'
      this.alertMessage = this.$t('data_updated')
      this.showAlert = true
      setTimeout(() => {
        this.showAlert = false
        this.alertType = 'success'
        this.alertMessage = null
      }, 1000)
      return res
    },
    reloadDataForCsubStageUpdate() {
      this.closeModal('stage')
      this.alertType = 'success'
      this.alertMessage = this.$t('data_updated')
      this.showAlert = true
      setTimeout(() => {
        this.showAlert = false
        this.alertType = 'success'
        this.alertMessage = null
      }, 1000)
    },
    // Close Modal
    closeModal(type) {
      if (type == 'sub_stage') {
        this.updateProjectState = false
        return
      }
      if (type == 'stage') {
        this.showModalStage = false
        this.stateToUpdate = null
        return
      }
    }
  },
  mounted() {
    // FETCH LIST TAG GLOBAL£
    // if (localStorage.getItem('typeProject')) {
    //   // field_id static = 1933
    //   this.fetchCustomFieldsByTypeProjet({
    //     field_id: 1933,
    //     type_projet_id: localStorage.getItem('typeProject')
    //   })
    // }
  }
}
</script>
<style scoped lang="scss">
.top-menu-filter-immo {
  width: 100%;
  height: 50px;
  padding: 6px 16px 6px 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(0deg, #ece8f4, #ece8f4),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #ece8f4;
  background: #ffffff;
  .right-block,
  .left-block {
    display: flex;
  }
  .vertical-line {
    border-right: 1px solid #c9c8d3; /* Changer 1px selon la largeur souhaitée */
    margin-left: 0px;
  }
}
</style>
