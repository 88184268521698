<template>
  <custom-drop-down-menu
    :styleComponent="styleComponent"
    @eventMenu="eventMenu"
  >
    <template #header>
      <div class="header-check-filter-immo" :class="{ active: actionMenu }">
        <font-awesome-icon
          :icon="iconFilter"
          v-if="iconFilter"
          class="icon-header"
        />
        <div class="header-title">{{ $t(keyFilter) }} {{ computedTitle }}</div>
        <font-awesome-icon :icon="['far', 'chevron-down']" class="icon-open" />
      </div>
    </template>
    <template #body>
      <div
        class="radio-filter-mul-immo"
        v-if="computedOptions && computedOptions.length"
      >
        <v-checkbox
          @change="handelInputRadio"
          v-for="item in computedOptions"
          :key="item.id"
          v-model="filterValue"
          :label="item[label]"
          color="#704ad1"
          :value="item"
          hide-details
        ></v-checkbox>
      </div>
      <div v-else class="font-text text-capitalize font-sz-12  text-center">
        {{ $t('noResult') }}
      </div>
      <v-divider class="pb-3 mt-2"></v-divider>
      <v-text-field
        class="field-search-filter-mul-immo"
        v-model="search"
        :append-icon="'mdi-magnify'"
        outlined
        hide-details
        single-line
        :label="$t('search')"
      ></v-text-field> </template
  ></custom-drop-down-menu>
</template>

<script>
import customDropDownMenu from './customDropDownMenu.vue'
export default {
  props: {
    iconFilter: { required: false },
    value: { required: true },
    label: { default: 'full_name' },
    options: { required: true },
    keyFilter: { default: 'ownerId' },
    styleComponent: { required: false }
  },
  data() {
    return {
      loading: false,
      search: null,
      filterValue: this.value,
      actionMenu: false
    }
  },
  computed: {
    computedOptions() {
      return this.search
        ? this.options.filter(item =>
            item[this.label].toLowerCase().includes(this.search.toLowerCase())
          )
        : this.options
    },
    computedTitle() {
      let selected = this.filterValue.length
        ? ' : ' + this.filterValue[0][this.label]
        : ``
      //  : ${this.$t('any')}
      let athor =
        this.filterValue.length && this.filterValue.length > 1
          ? ' +' + (this.filterValue.length - 1)
          : ''
      return selected + athor
    }
  },
  methods: {
    async handelInputRadio() {
      this.$emit('handelFilter', this.filterValue)
    },
    eventMenu(event) {
      this.actionMenu = event
    }
  },
  components: { customDropDownMenu }
}
</script>

<style lang="scss">
.header-check-filter-immo {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  line-height: 24px;
  gap: 7px;
  border-radius: 4px;
  letter-spacing: 0px;
  color: #2f2f2f;
  padding: 0px 12px 0px 12px;
  align-items: center;
  justify-content: flex-start;
  background: #f1f5f9;
  cursor: pointer;
  &.active {
    background: #d3dde89e;
  }
  &:hover {
    background: #d3dde89e;
  }
  .header-title {
    // height: 12px;
    font-size: 12px;
    line-height: 9px;
    color: #2f2f2f;
    font-weight: 500;
  }
  .icon-header {
    height: 1.2em !important;
  }
  .icon-open {
    height: 1em !important;
  }
}
.field-search-filter-mul-immo {
  fieldset {
    border: 2px solid #704ad1 !important;
  }
  .v-input__append-inner,
  .v-icon.v-icon {
    color: #704ad1 !important;
  }
  .v-text-field__slot,
  .v-text-field__slot .v-label {
    color: #505050 !important;

    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 10px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
  }
  .v-input__slot {
    min-height: 36px !important;
  }
  .v-input__append-inner {
    margin-top: 8px !important;
  }
  .v-label {
    top: 14px !important;
  }
  .v-icon.v-icon {
    font-size: 21px !important;
  }
}
.radio-filter-mul-immo {
  margin-top: 0px;
  height: 165px;
  overflow-y: auto;
  .v-input--selection-controls {
    margin-top: 6px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }
  .v-radio {
    padding: 5px;
    &:hover {
      background: #ece8f4;
    }
  }
  .v-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: red !important;
  }
}
</style>
