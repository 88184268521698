<template>
  <div
    class="right-menu-filter-immo"
    :class="{ 'resize-menu-filter-immo': !openMenu }"
  >
    <v-expansion-panels multiple>
      <v-expansion-panel
        v-for="(item, i) in dataMenu"
        :key="'filter' + i"
        class="expansion-panel-class mt-0"
        :class="{
          active:
            (item.selectedValueTitle &&
              item.selectedValueTitle.id &&
              item.selectedValueTitle.id == item.id) ||
            item.selectedValueTitle === true
        }"
        active-class="expansion-panel-class"
      >
        <v-expansion-panel-header
          disable-icon-rotate
          class="badge"
          @click="item.functionToexecuteFoTitle(item)"
        >
          <span class="title-badge">
            <template v-if="item.hasContent">
              <font-awesome-icon
                :icon="['far', 'chevron-down']"
                class="icon-ch-down"/>
              <font-awesome-icon
                :icon="['far', 'chevron-right']"
                class="icon-ch-right"
            /></template>
            <span :class="{ 'underline ml-2': !item.hasContent }">{{
              item.name
            }}</span></span
          >
          <template v-slot:actions>
            <span class="occ-badge">
              {{ item.projets_count }}
            </span>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-if="item && item.hasContent"
          class="expansion-content-class"
        >
          <div
            v-for="(subItem, j) in item.data"
            :key="'sub-menu-' + i + j"
            @click="item.functionToexecute(subItem, item)"
            class="sub-menu"
            :class="{
              'active-sub-menu':
                item.selectedValue && item.selectedValue.id == subItem.id
            }"
          >
            <div class="title-sub-menu">{{ subItem.name }}</div>
            <div class="occ-sub-menu">{{ subItem.projets_count }}</div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: { dataMenu: { required: true }, openMenu: { default: true } },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.right-menu-filter-immo {
  width: 240px;
  min-width: 240px;
  padding: 8px 0px 32px 8px;
  transition: width 0.1s;
  height: calc(100vh - 207px);
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer !important;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: 0px 1px 0px 0px #0000001f inset;
    box-shadow: 0px 1px 0px 0px #0000001f inset;
    border-radius: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 1px;
    height: 5px;
  }
  .expansion-panel-class {
    margin-bottom: 6px;
    background: transparent !important ;

    &::before {
      box-shadow: none !important;
    }
    &::after {
      border-top: 0px !important;
    }

    .badge {
      height: 40px;
      padding: 6px 16px 6px 16px;
      border-radius: 4px;
      border: 0px 0px 2px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px !important;
      .title-badge,
      .occ-badge {
        font-size: 11px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: center;
        color: #2f2f2f;
      }
      .title-badge {
        text-transform: uppercase;
        text-align-last: left;
        .icon-ch-down,
        .icon-ch-right {
          height: 10px;
          margin-right: 6px;
          vertical-align: baseline;
        }
        .icon-ch-down {
          display: none;
        }
      }
      .underline {
        text-decoration: underline;
      }
    }
    &.active {
      margin-top: 0px !important;
      .badge {
        background: #efe8ff;
        color: #45148f;
        .title-badge,
        .occ-badge {
          color: #45148f;
        }
      }
      .title-badge .icon-ch-right {
        display: none;
      }
      .title-badge .icon-ch-down {
        display: inline-block;
      }
    }

    .sub-menu {
      height: 35px;
      padding: 6px 6px 6px 9px;
      border-radius: 4px;
      border: 0px 0px 2px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 35px !important;
      cursor: pointer;
      margin-top: 3px;
      &:hover {
        background: #ece8f4;
      }
      .title-sub-menu,
      .occ-sub-menu {
        font-size: 11px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.01em;
        text-align: center;
        color: #2f2f2f;
   
        text-transform: uppercase;
        text-align: left;
      }
    }
    .active-sub-menu {
      // margin-top: 0px !important;
      background: #efe8ff;
      color: #45148f;

      .title-sub-menu,
      .cc-sub-menu {
        color: #45148f;
      }
    }
  }
}
.resize-menu-filter-immo {
  width: 0px;
  min-width: 0px;
  transition: width 0.2s;
  padding: 0px;
  .expansion-panel-class {
    display: none;
  }
}
</style>
<style lang="scss">
.right-menu-filter-immo {
  .expansion-panel-class {
    .expansion-content-class .v-expansion-panel-content__wrap {
      padding: 0px 0px 0px 24px !important;
    }
  }
  .v-expansion-panel-header__icon--disable-rotate {
    display: block !important; /* Assure que les actions sont toujours affichées */
  }
}
</style>
