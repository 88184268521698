<template>
  <div class="immo-page">
    <template v-if="!openModalFileProjet">
      <!-- Top Menu Filter -->
      <topMenuFilter
        :key="keyTopMenuFilter"
        :showIcons="selectedItems.length > 0"
        :computedListView="computedListView"
        @refresh="refresh"
        :assing="owner_id"
        :contact="contact_id"
        :tag="tag_id"
        @assingFilter="handelAssingFilter"
        @contactFilter="handelContactFilter"
        @tagFilter="handelTagFilter"
        @exportProjects="exportProjects"
        @removeFilter="removeFilter"
        :selectedItems="selectedItems"
        :step="step"
        @handelFilterTable="handelFilterWithReset"
      />
      <!-- End Top Menu Filter -->

      <!-- Body Page Immo  -->
      <div class="body-immo-page">
        <rightMenuFilter
          :data-menu="computedMenuElement"
          :openMenu="openMenu"
        />
        <div
          class="lock-menu"
          :class="{ close: !openMenu }"
          @click="openMenu = !openMenu"
        >
          <font-awesome-icon
            :icon="
              openMenu ? ['far', 'chevron-left'] : ['far', 'chevron-right']
            "
          />
        </div>
        <dataTable
          :showTotal="showTotal"
          :perPage="perPage"
          @refresh="refresh"
          :Loader="getProjectsProcessing"
          :Total="getProjectsTotalRowsTableBoard"
          :computedHeaderColumnTable="computedHeaderColumnTable"
          :computedTableBoard="computedTableBoard"
          :computedLastPage="computedLastPage"
          @pagination="pagination"
          @openModalFileProjet="handelopenModalFileProjet"
          :computedRestPage="computedRestPage"
          @SlectedItems="handleSelectedItems"
          :width="openMenu ? 'calc(100% - 256px)' : 'calc(100% - 50px)'"
          :UpdateViewTable="UpdateViewTable"
          :resetCheckbox="resetCheckbox"
          :iconFilter="true"
          @handelHeaderFilter="handelContactFilter"
          :filterContact="contact_id"
        />
      </div>
      <!-- End Body Page Immo  -->
    </template>
    <template v-else>
      <FileProjet
        :data="computedSelectedProject"
        @closeFileEvent="closeFileEvent"
      ></FileProjet>
    </template>
    <!-- ----------------------- ALERT  -------------------------------------------- -->
    <div class="message width-100" v-if="showAlert">
      <AlertAction :message="alertMessage" :type="alertType"></AlertAction>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import rightMenuFilter from './filters/rightMenuFilter.vue'
import topMenuFilter from './filters/topMenuFilter.vue'
import dataTable from './immoTable/dataImmoTable.vue'
export default {
  data() {
    return {
      resetCheckbox: false,
      showTotal: false,
      selectedItems: [],
      step: null,
      stage: null,
      substage: null,
      perPage: 50,
      page: 1,
      openMenu: true,
      openModalFileProjet: false,
      selectedProject: null,
      restPage: false,
      owner_id: [],
      showAlert: false,
      alertMessage: null,
      alertType: 'succes',
      contact_id: [],
      keyTopMenuFilter: 0,
      tag_id: []
    }
  },
  computed: {
    ...mapGetters([
      'getEtapes',
      'getCurentProjectType',
      'getTableBoard',
      'getHeaderTable',
      'getProjectsTotalRowsTableBoard',
      'getProjectsProcessing',
      'getProjectsTypes',
      'getProjectsExtraProject',
      'getListEditView'
    ]),

    computedLastPage() {
      return Math.ceil(this.getProjectsTotalRowsTableBoard / this.perPage)
    },
    computedTableBoard: function() {
      if (this.getTableBoard && this.getTableBoard.length) {
        const newArray = this.getTableBoard
        return newArray
      }

      return []
    },
    // Header Colonne Table //
    computedHeaderColumnTable: function() {
      if (this.getHeaderTable && this.getHeaderTable.length) {
        return this.getHeaderTable.map(i => {
          return {
            text: i.name,
            value: i.key,
            width: 170,
            type: i.id == '1862' ? 'select' : 'text',
            links: i.id == '1862' ? '/api/contact-body' : null, // pour contact id id static
            ...i
          }
        })
      }

      return []
    },
    // Liste Edit View Table //
    computedListView: function() {
      if (this.getListEditView && this.getListEditView.length) {
        return this.getListEditView.map(i => {
          return {
            text: i.name,
            value: i.key,
            ...i
          }
        })
      }

      return []
    },
    computedMenuElement() {
      // if (this.openModalFileProjet) return []
      let total_step = this.step
        ? this.step.stages
            .map(i => i.projets_count)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )
        : 0
      let dataMenu = [
        {
          name: this.$t('all'),
          projets_count: total_step,
          hasContent: false,
          functionToexecute: () => {},
          functionToexecuteFoTitle: this.FetchAllData,
          data: [],
          selectedValueTitle: this.stage == null && this.substage == null
        }
      ]
      let dataSub = []
      if (this.step) {
        dataSub = this.step.stages.map(i => {
          i.hasContent = true
          i.data = i.subStages
          i.functionToexecuteFoTitle = this.siwtchFilterPipeline
          i.functionToexecute = this.changeSubStage
          i.selectedValue = { ...this.substage }
          i.selectedValueTitle = { ...this.stage }
          return i
        })
        dataMenu = [...dataMenu, ...dataSub]
      }
      return dataMenu
    },
    computedRestPage() {
      return this.restPage
    },
    computedSelectedProject() {
      return this.selectedProject
    }
  },
  methods: {
    ...mapActions([
      'fetchEtapeStage',
      'fetchEtapeSubstage',
      'fetchTableBoard',
      'fetchSingleProject',
      'fetchEtapes',
      'fetchProjetsTypes',
      'fetchHeaderColumnTableBoard',
      'setCurentTypeProject',
      'setLoaderThemeForProject',
      'fetchAllTypeEvent',
      'fetchListEditView',
      'UpdateViewTable',
      'getAllUsers',
      'exportProjectsImmo'
    ]),
    // refresh tabble content when update project data
    handelFilterWithReset() {
      this.selectedItems = []
      this.handelFilter()
      this.refreshFilter()
    },
    handelAssingFilter(items) {
      this.owner_id = items
      this.handelFilter()
    },
    handelContactFilter(items) {
      this.contact_id = items
      this.handelFilter()
    },
    handelTagFilter(tag) {
      this.tag_id = tag
      this.handelFilter()
    },
    removeFilter() {
      this.owner_id = []
      this.tag_id = []
      this.contact_id = []
      this.refreshComponentTopMenuFilter()
      this.handelFilter()
    },
    refreshComponentTopMenuFilter() {
      this.keyTopMenuFilter++
    },
    async refresh() {
      await this.fetchHeaderColumnTableBoard(this.getCurentProjectType.id)
      // this.handelFilter()
    },
    handleSelectedItems(selectedItems) {
      this.selectedItems = selectedItems
    },
    async refreshFilter() {
      await this.fetchEtapeStage({
        step_id: this.step.id,
        etape: this.step,
        isNotLoading: true
      })
      if (this.step?.stages?.length) {
        this.stage =
          this.stage != null
            ? this.computedMenuElement.find(i => i.id == this.stage.id)
            : this.step.stages[0]
        await this.fetchEtapeSubstage({
          cstage_id: this.stage.id,
          stage: this.stage
        })
        if (this.substage && this.stage.data) {
          this.substage = this.stage.data.find(i => i.id == this.substage.id)
        }
      }
      this.handelFilter()
    },
    FetchAllData() {
      this.stage = null
      this.substage = null
      this.page = 1
      this.restPage = !this.restPage
      this.handelFilter()
    },
    siwtchFilterPipeline(stage) {
      if (
        this.stage == null ||
        this.stage?.id != stage?.id ||
        this.stage?.subStages?.length == 0
      ) {
        this.stage = stage
        this.substage = null
        this.fetchEtapeSubstage({
          cstage_id: this.stage.id,
          stage: this.stage
        })
        this.page = 1
        this.restPage = !this.restPage
        this.handelFilter()
      }
    },
    changeSubStage(substage, stage) {
      this.stage = stage
      this.substage = substage
      this.page = 1
      this.restPage = !this.restPage
      this.handelFilter()
    },
    pagination(page) {
      this.page = page
      this.handelFilter()
    },
    async handelopenModalFileProjet(item, initType) {
      if (
        this.$route &&
        this.$route.name != 'project' &&
        !this.$route.params.id
      ) {
        this.$router.push('/projects/' + item.id)
      }
      this.openModalFileProjet = true
      this.selectedProject = item
      await this.fetchSingleProject({
        id: item.id,
        checked: true
      })
      if (
        this.getCurentProjectType?.id !=
          this.getProjectsExtraProject?.type_id &&
        initType
      ) {
        let step_object = this.getProjectsTypes.find(
          i => i.id == this.getProjectsExtraProject.type_id
        )
        this.setCurentTypeProject(step_object)
      }
      this.fetchAllTypeEvent({ type_projet_id: this.getCurentProjectType.id })
      this.setLoaderThemeForProject(false)
      this.fetchEtapes({ type_id: this.getCurentProjectType.id })
    },
    async handelFilter() {
      this.resetCheckbox = !this.resetCheckbox
      await this.fetchTableBoard({
        type_id:
          this.getCurentProjectType && this.getCurentProjectType.id
            ? this.getCurentProjectType.id
            : this.getCurentProjectType,
        per_page: this.perPage,
        page: this.page,
        step_id: this.step.id,
        stage_id: this.stage && this.stage.id ? this.stage.id : null,
        substage_id:
          this.substage && this.substage.id ? this.substage.id : null,
        contact_id: this.contact_id,
        owner_id: this.owner_id,
        tag_id: this.tag_id
      })
    },
    async closeFileEvent() {
      this.openModalFileProjet = false
      this.selectedProject = null
      this.selectedItems = []
      if (this.$route && this.$route.path != '/projects') {
        this.$router.replace('/projects')
      }
      //update  step count
      this.fetchHeaderColumnTableBoard(this.getCurentProjectType.id)
      this.handelFilter()
      this.refreshFilter()
    },
    // export Projects
    async exportProjects() {
      let params = {}
      params.type_id =
        this.getCurentProjectType && this.getCurentProjectType.id
          ? this.getCurentProjectType.id
          : this.getCurentProjectType
      if (this.selectedItems.length) {
        params.projects = this.selectedItems.map(i => i.id)
      } else {
        params.step_id = this.step.id
        if (this.stage?.id) {
          params.stage_id = this.stage.id
        }
        if (this.substage?.id) {
          params.substage_id = this.substage.id
        }
        if (this.contact_id?.length) {
          params.contact_id = this.contact_id
        }
        if (this.owner_id?.length) {
          params.owner_id = this.owner_id
        }
        if (this.tag_id?.length) {
          params.tag_id = this.tag_id
        }
      }

      const response = await this.exportProjectsImmo(params)
      if (!response.succes) {
        this.alertMessage = response.error
        this.alertType = 'error'
        this.showAlert = true
        setTimeout(() => {
          this.showAlert = false
        }, 4000)
      }
    }
  },
  async mounted() {
    let contact_ids = JSON.parse(localStorage.getItem('contacts_ids'))
    if (contact_ids?.length) {
      this.contact_id = contact_ids
      this.refreshComponentTopMenuFilter()
      localStorage.removeItem('contacts_ids')
    }
    let router_project_id =
      this.$route.name == 'project' &&
      this.$route.params &&
      this.$route.params.id
    if (router_project_id) {
      this.handelopenModalFileProjet({ id: this.$route.params.id }, true)
    }

    if (this.getCurentProjectType?.id) {
      await this.fetchEtapes({
        type_id: this.getCurentProjectType.id,
        isNotLoading: true
      }).then(async () => {
        if (this.getEtapes.length) {
          this.step = this.getEtapes[0]
          await this.fetchEtapeStage({
            step_id: this.step.id,
            etape: this.step,
            isNotLoading: true
          }).then(() => {
            this.stage =
              this.contact_id && this.contact_id.length
                ? null
                : this.step.stages[0]
          })
        }
      })
    }
    this.fetchListEditView(this.getCurentProjectType.id)
    this.getAllUsers()
    if (router_project_id) {
      return
    }
    await this.fetchHeaderColumnTableBoard(this.getCurentProjectType.id)
    this.handelFilter()
  },
  watch: {
    async $route(route) {
      if (route.name == 'project' && route.params.id) {
        this.handelopenModalFileProjet({ id: route.params.id }, true)
      }
      if (route.name == 'projects') {
        this.closeFileEvent()
      }
    }
  },
  components: {
    topMenuFilter,
    rightMenuFilter,
    dataTable,
    FileProjet: () => import('./immoTable/FileProjet.vue'),
    AlertAction: () => import('@/components/CrmIla26Component/AlertAction.vue')
  }
}
</script>
<style lang="scss" scoped>
.immo-page {
  height: 100%;
  background-color: #f6f6f6;
  padding: 8px 8px 0px 8px;

  .body-immo-page {
    margin-top: 10px;
    background: #f7f5fd;
    border: 1px solid #ece8f4;
    display: flex;
    border-radius: 8px 8px 0px 0px;
    height: 100%;
    .lock-menu {
      width: 20px;
      height: 64px;
      padding: 23px 0px 22px 0px;
      border-radius: 4px 0px 0px 4px;
      background: #dcd2ed;
      align-self: center;
      display: flex;
      align-items: center;
      svg {
        height: 16px;
        width: 27px;
        color: #5b2ecf;
      }
      &.close {
        border-radius: 0px 4px 4px 0px;
        left: 8px;
      }
    }
  }
}
</style>
