<template>
  <div class="d-flex">
    <custom-drop-down-menu :closeCustemDropMenu="closeCustemDropMenu">
      <template #header>
        <div class="header-assign-immo">
          <font-awesome-icon :icon="['far', 'user-check']" />
          <div class="header-title">
            {{ $t(labelKey) }}
          </div>
        </div>
      </template>
      <template #body>
        <div v-if="loading" class="custom-full-loader">
          <v-progress-circular
            indeterminate
            color="#3a167f"
          ></v-progress-circular>
        </div>
        <v-text-field
          class="field-search-assign-immo"
          v-model="search"
          :append-icon="'mdi-magnify'"
          outlined
          hide-details
          single-line
          :label="$t('search')"
        ></v-text-field>
        <v-divider class="pb-3 mt-2"></v-divider>
        <div class="success-msg-s text-center mt-1 mb-1" v-if="succesMessage">
          {{ $t(succesMessage) }}
        </div>
        <div class="error-msg" v-if="errorMessage">{{ errorMessage }}</div>
        <v-radio-group
          v-model="assign"
          :label="''"
          class="radio-assign-immo"
          hide-details
        >
          <v-radio
            v-for="item in computedOptions"
            :key="item.id"
            :label="item[label]"
            :value="item.id"
            class="radio-btn"
            color="#5C2DD3"
            @click="handelInputRadio(item)"
          ></v-radio
        ></v-radio-group> </template
    ></custom-drop-down-menu>
    <AlertAction
      :message="alertMessage"
      :type="alertType"
      v-if="showAlert"
    ></AlertAction>
  </div>
</template>

<script>
import customDropDownMenu from './customDropDownMenu.vue'
import AlertAction from '@/components/CrmIla26Component/AlertAction.vue'

export default {
  props: {
    iconFilterAssign: { required: false },
    value: { required: true },
    updateFunction: { default: () => {} },
    label: { default: 'full_name' },
    options: { required: true },
    labelKey: { default: 'assign' }
  },
  data() {
    return {
      loading: false,
      search: null,
      assign: this.value?.id ? this.value.id : null,
      succesMessage: null,
      errorMessage: null,
      closeCustemDropMenu: false,
      alertMessage: null,
      alertType: 'success',
      showAlert: false
    }
  },
  computed: {
    computedOptions() {
      return this.search
        ? this.options.filter(item =>
            item[this.label].toLowerCase().includes(this.search.toLowerCase())
          )
        : this.options
    }
  },
  methods: {
    async handelInputRadio(item) {
      this.loading = true
      const res = await this.updateFunction(item)
      if (res.succes) {
        this.succesMessage = res.data
        this.closeCustemDropMenu = !this.closeCustemDropMenu
        this.alertType = 'success'
        this.alertMessage = this.$t(res.data)
        this.showAlert = true
      } else {
        this.errorMessage = res.error
      }
      this.loading = false
      setTimeout(() => {
        this.showAlert = false
        this.assign = this.value?.id ? this.value.id : null
        this.succesMessage = null
        this.alertType = 'success'
        this.alertMessage = null
      }, 1000)
    }
  },
  components: { customDropDownMenu, AlertAction }
}
</script>

<style lang="scss">
.header-assign-immo {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  line-height: 1rem;
  gap: 7px;
  letter-spacing: 0px;
  color: #2f2f2f;
  padding: 0px 12px 0px 12px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &:hover {
    background: #f1f5f9;
  }
  .header-title {
    // height: 12px;
    font-size: 12px;
    line-height: 9px;
  }
  .svg-inline--fa {
    height: 1.2em !important;
  }
}
.field-search-assign-immo {
  margin-top: 4px;
  fieldset {
    border: 2px solid #704ad1 !important;
  }
  .v-input__append-inner,
  .v-icon.v-icon {
    color: #704ad1 !important;
  }
  .v-text-field__slot,
  .v-text-field__slot .v-label {
    color: #505050 !important;

    font-size: 12px;
    font-weight: 400;
    line-height: 10px !important;
    letter-spacing: 0px;
    text-align: left;
  }
  .v-input__slot {
    min-height: 36px !important;
  }
  .v-input__append-inner {
    margin-top: 8px !important;
  }
  .v-label {
    top: 14px !important;
  }
  .v-icon.v-icon {
    font-size: 21px !important;
  }
}
.radio-assign-immo {
  margin-top: 0px;
  height: 165px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }
  .v-radio {
    padding: 5px;
    &:hover {
      background: #ece8f4;
    }
  }
  .v-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
}
</style>
