<template>
  <div class="d-flex">
    <div class="header-for-filter-immo" @click="handelOpenTagModal">
      <font-awesome-icon :icon="['far', 'tags']" />
      <div class="item-title">
        {{ $t('add_tag') }}
      </div>
    </div>
    <v-dialog
      v-model="showModalTags"
      max-width="550"
      persistent
      scrollable
      class="tagsModal"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header ">
            {{ $t('add_tag') }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('showModalTags')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="showModalTags" class="mt-2">
            <!-- TAGS -->
            <v-row class="row">
              <v-col>
                <v-combobox
                  v-model="selected"
                  :items="tags"
                  :search-input.sync="search"
                  hide-selected
                  :label="$t('view_tag')"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  :delimiters="[',']"
                  outlined
                  :allow-overflow="false"
                  :persistent-placeholder="true"
                  :loading="listLoading"
                  :disabled="false"
                  item-color="#5C2DD3"
                  color="#5C2DD3"
                  :placeholder="
                    tags && tags.length
                      ? $t('msgPlaceholderCombobox')
                      : $t('add_new_tag')
                  "
                  :hide-no-data="!search"
                  @keydown.enter.prevent="addNewTag"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ search
                          }}<span> {{ '( ' + $t('new_tag') + ' )' }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      label
                      small
                      color="#5C2DD3"
                      text-color="white"
                      :outlined="false"
                      class="chip-tag"
                    >
                      <span class="pr-2">
                        {{ item.text }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)"
                        >mdi-close</v-icon
                      >
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="errors" class="error-msg">
              <ul v-if="Array.isArray(errors)">
                <li v-for="(e, index) in errors" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errors }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#45148f"
            dark
            @click.prevent.stop="addTags"
            :loading="loading"
          >
            {{ $t('update_key') }}
          </v-btn>
          <v-btn text @click="closeDialog('showModalDiscardBtn')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DISCART -->
    <v-dialog v-model="showModalDiscard" max-width="400" persistent scrollable>
      <v-card class="discardTagsModal">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header ">
            {{ $t('discard_update') }}
          </v-label>
        </v-card-title>

        <v-card-text class="body-card center-text  mt-2">
          <b class="text-message">{{ $t('discard_msg_tag') }}</b>
        </v-card-text>
        <v-card-actions class="actions-msg-btn-modal flex justify-end ">
          <v-btn
            color="#45148f"
            dark
            @click="closeDialog('discard')"
            class="btnTagDiscad"
          >
           <span class="title">{{ $t('discard') }}</span> 
          </v-btn>
          <v-btn
            text
            @click="closeDialog('showModalDiscard')"
            class="btnTagDiscad"
            ><span  class="title">{{ $t('back') }}</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: { handelUpdateTags: { required: true } },
  data() {
    return {
      showModalTags: false,
      loading: false,
      errors: null,
      search: null,
      selected: [],
      tags: [],
      showModalDiscard: false,
      listLoading: false
    }
  },
  methods: {
    ...mapActions(['fetchTagList']),
    async handelOpenTagModal() {
      this.showModalTags = true
      this.listLoading = true
      const res = await this.fetchTagList({ field_id: 1933 })
      if (res.succes) {
        this.tags = res.data.map(i => {
          return { ...i, text: i.label }
        })
      }
      this.listLoading = false
    },
    async addTags() {
      this.loading = true
      const res = await this.handelUpdateTags(this.selected)
      if (res.succes) {
        this.closeDialog('showModalTags')
      }
      this.loading = false
    },
    closeDialog(ref) {
      if (ref == 'showModalTags') {
        this.showModalTags = false
        this.selected = []
        this.tags = []
      } else if (ref == 'showModalDiscard') {
        this[ref] = false
        this.showModalTags = true
      } else if (ref == 'showModalDiscardBtn') {
        this.showModalTags = false
        if (this.selected.length) {
          this.showModalDiscard = true
        }
      } else if (ref == 'discard') {
        this.showModalDiscard = false
        this.selected = []
        this.tags = []
      }
    },
    addNewTag() {
      const newTag = { text: this.search }
      let exist = this.selected.find(i => i.text == this.search)
      let data = exist && exist.id ? exist : newTag

      if (exist) {
        this.selected = this.selected.filter(i => i.text != this.search)
      } else {
        let existInlist = this.tags.find(i => i.text == this.search)
        if (existInlist) {
          data = existInlist
        }
      }
      this.selected.push(data)

      this.search = ''
      //call api to add new tag
    }
  }
}
</script>

<style scoped lang="scss">
.chip-tag {
  border-radius: 4px !important;
  background: #5c2dd3 !important;
  border-color: #5c2dd3 !important;
  .chip-tag .v-chip__content {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: center;
    color: #fff !important;
  }
}
.tagsModal {
  .label-heade {
    color: #45148f !important;
  }
}
.discardTagsModal {
  font-size: 10px;
  .label-header {
    color: #45148f !important;
    font-size: 18px !important;
  }
  .text-message {
    font-size: 11px !important;
  }
  .btnTagDiscad .title {
    font-size: 10px !important;
  }
}
</style>
